export default class UrlConfig {
  constructor(host) {
    this.host = host
  }

  getBaseUri() {
    let baseUrl = ''
    switch (this.host) {
      case 'b2bpro.golog.my':
        baseUrl = 'https://b2bpro.golog.my/'
        break
      case 'b2bprostaging.golog.my':
        baseUrl = 'https://b2bprostaging.golog.my/'
        break
      case 'b2bprodemo2.golog.my':
        baseUrl = 'https://b2bprodemo2.golog.my/'
        break
      default:
        baseUrl = this.host
        break
    }
    return baseUrl
  }

  getApiUrl() {
    let appUrl = ''
    switch (this.host) {
      case 'b2bpro.golog.my':
        appUrl = 'https://my-app-qx8gs.ondigitalocean.app/api/'
        break
      case 'b2bprostaging.golog.my':
        appUrl = 'https://b2b-laravel-staging-pwvly.ondigitalocean.app/api/'
        break
      case 'b2bprodemo2.golog.my':
        appUrl = 'https://my-app-g2quh.ondigitalocean.app/api/'
        break
      default:
        appUrl = 'https://b2b-laravel-staging-pwvly.ondigitalocean.app/api/'
        break
    }
    return appUrl
  }

  getBarcodeUrl() {
    let appUrl = ''
    switch (this.host) {
      case 'b2bpro.golog.my':
        appUrl = 'https://my-app-qx8gs.ondigitalocean.app/barcode/'
        break
      case 'b2bprostaging.golog.my':
        appUrl = 'https://b2b-laravel-staging-pwvly.ondigitalocean.app/barcode/'
        break
      case 'b2bprodemo2.golog.my':
        appUrl = 'https://my-app-g2quh.ondigitalocean.app/barcode/'
        break
      default:
        appUrl = 'https://b2b-laravel-staging-pwvly.ondigitalocean.app/barcode/'
        break
    }
    return appUrl
  }
}
