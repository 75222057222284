import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    message: '',
    type: '',
  },
  getters: {

  },
  mutations: {
    SET_MESSAGE(state, message) {
      state.message = message
    },
    SET_TYPE(state, type) {
      state.type = type
    },
  },
  actions: {
    showNotification({ commit }, { message = '', type = '' }) {
      commit('SET_MESSAGE', message)
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: `${message}`,
          icon: `${type === 'success' ? 'ThumbsUpIcon' : 'XCircleIcon'}`,
          variant: `${type}`,
        },
      })
    },
  },
}
