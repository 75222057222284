import i18n from '@/libs/i18n'

export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/pages/inside/Dashboard.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Dashboard'),
      breadcrumb: [
        {
          text: i18n.t('Dashboard'),
          active: true
        }
      ]
    }
  },
  {
    path: '/create-delivery',
    name: 'createdelivery',
    component: () => import('@/views/pages/inside/CreateDelivery.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Create Delivery'),
      breadcrumb: [
        {
          text: 'Create Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/fleet-forwarding-create-delivery',
    name: 'fleetforwardingcreatedelivery',
    component: () =>
      import('@/views/pages/inside/fleetforwarding/CreateDelivery.vue'),
    meta: {
      group: 2,
      pageTitle: 'Freight Forwarding Delivery',
      breadcrumb: [
        {
          text: 'Freight Forwarding Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/fleet-forwarding-order-tracker',
    name: 'fleetforwardingordertracker',
    component: () =>
      import('@/views/pages/inside/fleetforwarding/OrderTracker.vue'),
    meta: {
      group: 2,
      pageTitle: 'Freight Forwarding Delivery',
      breadcrumb: [
        {
          text: 'Freight Forwarding Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/fleet-forwarding-delivery-history',
    name: 'fleetforwardingdeliveryhistory',
    component: () =>
      import('@/views/pages/inside/fleetforwarding/OrderHistory.vue'),
    meta: {
      group: 2,
      pageTitle: 'Freight Forwarding Delivery',
      breadcrumb: [
        {
          text: 'Freight Forwarding Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/fleet-forwarding-delivery-success',
    name: 'fleetforwardingdeliverysuccess',
    component: () =>
      import('@/views/pages/inside/fleetforwarding/OrderSuccess.vue'),
    meta: {
      group: 2,
      pageTitle: 'Freight Forwarding Delivery',
      breadcrumb: [
        {
          text: 'Freight Forwarding Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/fleet-forwarding-delivery-summary/:id',
    name: 'fleetforwardingdeliverysummary',
    component: () =>
      import('@/views/pages/inside/fleetforwarding/OrderSummary.vue'),
    meta: {
      group: 2,
      pageTitle: 'Freight Forwarding Delivery',
      breadcrumb: [
        {
          text: 'Freight Forwarding Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/fleet-forwarding-delivery-proceed-order/:id/:smarttruckid',
    name: 'fleetforwardingdeliveryproceedorder',
    component: () =>
      import('@/views/pages/inside/fleetforwarding/ProceedOrder.vue'),
    meta: {
      group: 2,
      pageTitle: 'Freight Forwarding Delivery',
      breadcrumb: [
        {
          text: 'Freight Forwarding Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/Chartered-truck-create-delivery',
    name: 'Charteredtruckcreatedelivery',
    component: () =>
      import('@/views/pages/inside/Charteredtruck/CreateDelivery.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Chartered Truck Delivery'),
      breadcrumb: [
        {
          text: 'Chartered Truck Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/Chartered-truck-order-tracker',
    name: 'Charteredtruckordertracker',
    component: () =>
      import('@/views/pages/inside/Charteredtruck/OrderTracker.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Chartered Truck Delivery'),
      breadcrumb: [
        {
          text: 'Chartered Truck Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/Chartered-truck-delivery-history',
    name: 'Charteredtruckdeliveryhistory',
    component: () =>
      import('@/views/pages/inside/Charteredtruck/OrderHistory.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Chartered Truck Delivery'),
      breadcrumb: [
        {
          text: 'Chartered Truck Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/Chartered-truck-delivery-proceed-order/:id/:smarttruckid',
    name: 'Charteredtruckdeliveryproceedorder',
    component: () =>
      import('@/views/pages/inside/Charteredtruck/ProceedOrder.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Chartered Truck Delivery'),
      breadcrumb: [
        {
          text: 'Chartered Truck Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/Chartered-truck-delivery-success',
    name: 'Charteredtruckdeliverysuccess',
    component: () =>
      import('@/views/pages/inside/Charteredtruck/OrderSuccess.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Chartered Truck Delivery'),
      breadcrumb: [
        {
          text: 'Chartered Truck Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/Chartered-truck-delivery-summary/:id',
    name: 'Charteredtruckdeliverysummary',
    component: () =>
      import('@/views/pages/inside/Charteredtruck/OrderSummary.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Chartered Truck Delivery'),
      breadcrumb: [
        {
          text: 'Chartered Truck Delivery',
          active: true
        }
      ]
    }
  },
  {
    path: '/bulk-delivery',
    name: 'bulkdelivery',
    component: () => import('@/views/pages/inside/BulkDelivery.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Bulk Delivery')
    }
  },
  {
    path: '/my-cart',
    name: 'my-cart',
    component: () => import('@/views/pages/inside/MyCart.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Delivery Cart'),
      breadcrumb: [
        {
          text: 'Delivery Cart',
          active: true
        }
      ]
    }
  },
  {
    path: '/delivery-transaction',
    name: 'deliverytransaction',
    component: () => import('@/views/pages/inside/DeliveryTransaction.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Delivery Transaction'),
      breadcrumb: [
        {
          text: 'Delivery Transaction',
          active: true
        }
      ]
    }
  },
  {
    path: '/delivery-history',
    name: 'deliveryhistory',
    component: () => import('@/views/pages/inside/DeliveryHistory.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Delivery History'),
      breadcrumb: [
        {
          text: 'Delivery History',
          active: true
        }
      ]
    }
  },
  {
    path: '/refund-requests',
    name: 'RefundRequests',
    component: () => import('@/views/pages/inside/RefundRequests.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Refund Requests'),
      breadcrumb: [
        {
          text: 'Refund Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () =>
      import('@/views/pages/inside/account-setting/AccountSetting.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('My Profile'),
      breadcrumb: [
        {
          text: 'Profile',
          active: true
        }
      ]
    }
  },
  {
    path: '/order-successful/:type/:id',
    name: 'ordersuccessful',
    component: () => import('@/views/pages/inside/OrderSuccessful.vue'),
    meta: {
      group: 2,
      layout: 'full'
    }
  },
  {
    path: '/delivery-summary/:id',
    name: 'deliverysummary',
    component: () => import('@/views/pages/inside/DeliverySummary.vue'),
    meta: {
      group: 2,
      pageTitle: ''
    }
  },
  {
    path: '/track-order/:id?',
    name: 'trackorder',
    component: () => import('@/views/pages/inside/TrackAndTrace.vue'),
    meta: {
      group: 2,
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Track Order',
          active: true
        }
      ]
    }
  },
  {
    path: '/inquiries',
    name: 'Inquiries',
    component: () => import('@/views/pages/inside/MyInquiries.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('Inquiries'),
      breadcrumb: [
        {
          text: 'Inquiries',
          active: true
        }
      ]
    }
  },
  {
    path: '/inquiries-activity-logs',
    name: 'InquiriesActivityLogs',
    component: () => import('@/views/pages/inside/InquiriesActivityLogs.vue'),
    meta: {
      group: 2,
      pageTitle: i18n.t('inquiries_activity_logs'),
      breadcrumb: [
        {
          text: 'Inquiries Activity Logs',
          active: true
        }
      ]
    }
  },
  {
    path: '/order-tracking/:id?',
    name: 'OrderTracking',
    component: () => import('@/views/pages/inside/OrderTracking.vue'),
    meta: {
      group: 2,
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Order Tracking',
          active: true
        }
      ]
    }
  },
  {
    path: '/delivery-confirmed/:referenceNo/:mop',
    name: 'delivery-confirmed',
    component: () => import('@/views/pages/inside/DeliveryConfirmed.vue'),
    props: true,
    meta: {
      group: 2,
      layout: 'full'
    }
  },
  {
    path: '/log-points',
    name: 'log-points',
    component: () => import('@/views/pages/inside/LogPoints/LogPoints.vue'),
    meta: {
      pageTitle: 'Log Points',
      breadcrumb: [
        {
          text: 'Log Points',
          active: true
        }
      ],
      resource: 'Log Points',
      action: 'manage'
    }
  },
  {
    path: '/log-points/summary/:id',
    name: 'log-point-summary',
    component: () =>
      import('@/views/pages/inside/LogPoints/LogPointSummary.vue'),
    meta: {
      pageTitle: 'Log Points',
      breadcrumb: [
        {
          text: 'Log Points',
          active: true
        }
      ]
    }
  }
]
