import axios from '@axios'

export default {
  namespaced: true,
  state: {
    orders: [],
    order: {},
    links: null,
    meta: null,
    isLoading: false,
    items: [],
    isFetchingPrice: false,
    hasPriceSet: false,
    vouchers: [],
  },
  getters: {
    orders: state => state.orders,
    order: state => state.orders,
    links: state => state.links,
    meta: state => state.meta,
    isLoading: state => state.isLoading,
    items: state => state.items,
    isFetchingPrice: state => state.isFetchingPrice,
    hasPriceSet: state => state.hasPriceSet,
    vouchers: state => state.vouchers,
  },
  mutations: {
    SET_ORDERS_DATA_SUCCESS(state, response) {
      state.orders = response.data
    },
    SET_ORDERS_LINKS_SUCCESS(state, response) {
      state.links = response.links
    },
    SET_ORDERS_META_SUCCESS(state, response) {
      state.meta = response.meta
    },
    SET_ORDER_DATA_SUCCESS(state, response) {
      state.order = response.data
    },
    SET_ORDERS_FAILURE(state) {
      state.orders = []
      state.links = null
      state.meta = null
      state.isLoading = false
      state.actionType = null
    },
    SET_IS_LOADING(state, value) {
      state.isLoading = value
    },
    SET_ORDERS_TO_EMPTY(state) {
      state.orders = []
      state.order = {}
    },
    SET_PRODUCT_DETAILS(state, response) {
      state.items = [
        { label: 'Quantity', value: response.data.quantity },
        { label: 'Type of Packaging', value: response.data?.packaging?.text ?? '' },
        { label: `Size(${response.data.unit})`, value: response.data.size },
        { label: 'SKU', value: response.data.sku },
        { label: 'Expiry Date', value: response.data.po_expiry_date },
        { label: 'Remarks', value: response.data.remarks },
      ]
    },
    SET_VOUCHERS(state, response) {
      state.vouchers = response.data
    },
  },
  actions: {
    async fetchOrders({ commit }, { query = '' }) {
      try {
        commit('SET_ORDERS_TO_EMPTY')
        commit('SET_IS_LOADING', true)
        const { data } = await axios.get(
          `orders?${query}`,
        )
        commit('SET_ORDERS_DATA_SUCCESS', data)
        commit('SET_ORDERS_LINKS_SUCCESS', data)
        commit('SET_ORDERS_META_SUCCESS', data)
        commit('SET_IS_LOADING', false)
      } catch (e) {
        commit('SET_ORDERS_FAILURE')
      }
    },
    async fetchOrder({ commit }, { orderId }) {
      try {
        commit('SET_ORDERS_TO_EMPTY')
        commit('SET_IS_LOADING', true)
        const response = await axios.get(
          `orders/${orderId}`,
        )
        if (response.status === 200) {
          commit('SET_ORDERS_DATA_SUCCESS', response.data)
          commit('SET_ORDERS_LINKS_SUCCESS', response.data)
          commit('SET_ORDERS_META_SUCCESS', response.data)
          commit('SET_PRODUCT_DETAILS', response.data)
        }
        commit('SET_IS_LOADING', false)
        return response
      } catch (e) {
        commit('SET_ORDERS_FAILURE')
        throw new Error('Something went wrong.', e)
      }
    },
    async updateOrder({ commit, dispatch }, { orderId, formData }) {
      try {
        const response = await axios.patch(`orders/${orderId}`, formData)
        if (response.status === 200) {
          dispatch('notification/showNotification', { message: 'Updated Successfully', type: 'success' }, { root: true })
        }
      } catch (e) {
        commit('SET_ORDERS_FAILURE')
        throw new Error('Something went wrong.', e)
      }
    },
    async storeOrder({ commit }, { orderData, step = 1 }) {
      try {
        commit('SET_IS_LOADING', true)
        const response = await axios.post('orders', { ...orderData, step })
        commit('SET_IS_LOADING', false)
        return response
      } catch (e) {
        commit('SET_ORDERS_FAILURE')
        throw new Error('Something went wrong.', e)
      }
    },
    async fetchVouchers({ commit }) {
      const response = await axios.get('/user-vouchers')
      if (response.status === 200) {
        commit('SET_VOUCHERS', response)
      }
    },
  },
}
