import axios from '@axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    orderForm: {
      client_id: null,
      category_id: null,
      pickup_id: null,
      dropoff_id: null,
      category_name: null,
      qty: null,
      sku: null,
      size: null,
      unit: 'cm',
      price: null,
      golog_cut: null,
      smarttruck_cut: null,
      pickup_charge: null,
      temperature: null,
      remarks: null,
      packaging: null,
      selectedPackaging: null,
      selectedRemarks: null,
      weight: null,
      is_pickup: null,
      delivery_date: null,
      po_expiry_date: null,
      po_number: null,
      invoice_number: null,
      tax_charge: null,
      is_over_size: 0,
      is_over_weight: 0,
      sender_address: null,
      outlet_address: null,
      type_of_market: null,
      recipient: null,
      custom_package: null,
      custom_remarks: null,
      length: null,
      width: null,
      height: null,
      selectedVoucher: null
    },
    charges: {
      subTotal: 0,
      voucherDiscounts: 0,
      pickUpCharge: 0,
      totalCharges: 0,
      price: 0,
      golog_cut: 0,
      smarttruck_cut: 0,
      pickup_charge: 0,
      tax_charge: 0,
      voucher: 0,
      chop_sign: 0
    },
    isFetchingFields: false,
    itemsTable: [],
    isFetchingPrice: false,
    hasPriceSet: true,
    userAddresses: [],
    gologAddresses: [],
    markets: [],
    senderAddresses: [],
    categories: [],
    packaging: [],
    allClients: [],
    remarks: [],
    outlets: [],
    recipients: [],
    pickUpAddresses: []
  },
  getters: {
    orderForm: (state) => state.orderForm,
    itemsTable: (state) => [
      {
        po_number: state.orderForm.po_number,
        product: state.categories?.find(
          (category) => category.value === state.orderForm.category_id
        )?.text,
        quantity: state.orderForm.qty,
        packaging:
          state.orderForm.selectedRemarks === 'Others'
            ? state.orderForm.custom_remarks
            : state.orderForm.selectedRemarks,
        sku: state.orderForm.sku,
        unit_price: 0,
        amount: 0
      }
    ],
    charges: (state) => state.charges,
    isFetchingPrice: (state) => state.isFetchingPrice,
    hasPriceSet: (state) => state.hasPriceSet,
    remarks: (state) => state.remarks,
    categories: (state) => state.categories,
    packaging: (state) => state.packaging,
    markets: (state) => state.markets,
    recipients: (state) => state.recipients,
    outlets: (state) => state.outlets,
    form: (state) => state.form,
    pickUpAddresses: (state) => state.pickUpAddresses,
    isFetchingFields: (state) => state.isFetchingFields,
    filteredForm: (state) => ({
      client_id: state.orderForm.recipient
        ? state.orderForm.recipient.id
        : null,
      pickup_id: state.orderForm.sender_address
        ? state.orderForm.sender_address.id
        : null,
      dropoff_id: state.orderForm.outlet_address
        ? state.orderForm.outlet_address.id
        : null,
      type_of_market: state.orderForm.type_of_market,
      recipient: state.orderForm.recipient,
      is_pickup: state.orderForm.is_pickup,
      delivery_date: state.orderForm.delivery_date,
      outlet_address: state.orderForm.outlet_address,
      sender_address: state.orderForm.sender_address,
      category_id: state.orderForm.category_id,
      category_name: state.categories.find(
        (category) => category.value === state.orderForm.category_id
      )?.text,
      qty: state.orderForm.qty,
      sku: state.orderForm.sku,
      size: state.orderForm.size,
      unit: state.orderForm.unit,
      temperature: state.orderForm.temperature,
      remarks:
        state.orderForm.selectedRemarks === 'Others'
          ? state.orderForm.custom_remarks
          : state.orderForm.selectedRemarks,
      packaging:
        state.orderForm.selectedPackaging === 'Others'
          ? state.orderForm.custom_package
          : state.orderForm.selectedPackaging,
      weight: state.orderForm.weight,
      po_expiry_date: state.orderForm.po_expiry_date,
      po_number: state.orderForm.po_number,
      invoice_number: state.orderForm.invoice_number,
      price: state.charges.totalCharges,
      golog_cut: state.charges.gologCut,
      smarttruck_cut: state.charges.smarttruckCut,
      pickup_charge: state.charges.pickUpCharge,
      tax_charge: state.charges.tax_charge,
      unit_price: state.charges.unitPrice,
      voucher: state.orderForm.selectedVoucher,
      is_over_size: Vue.validate.isSizeOnDoubleCharge(
        state.orderForm.size,
        state.orderForm.unit
      ),
      is_over_weight: Vue.validate.isWeightOnDoubleCharge(
        state.orderForm.weight
      )
    })
  },
  mutations: {
    RESET_SENDER_ADDRESS(state) {
      state.orderForm.sender_address = null
    },
    RESET_RECIPIENT(state) {
      state.orderForm.client_id = null
      state.orderForm.recipient = null
    },
    RESET_OUTLET_ADDRESS(state) {
      state.orderForm.dropoff_id = null
      state.orderForm.outlet_address = null
    },
    SET_DELIVERY_TYPE(state, type) {
      state.orderForm.is_pickup = type
    },
    UPDATE_ORDER_FORM(state, data) {
      state.orderForm = { ...state.orderForm, ...data }
    },
    SET_CHARGES(state, response) {
      state.charges = response.data
    },
    SET_IS_FETCHING_PRICE(state, value) {
      state.isFetchingPrice = value
    },
    SET_HAS_PRICE_SET(state, value) {
      state.hasPriceSet = value
    },
    RESET_CHARGES(state) {
      state.charges = {
        subTotal: 0,
        voucherDiscounts: 0,
        pickUpCharge: 0,
        totalCharges: 0,
        price: 0,
        golog_cut: 0,
        smarttruck_cut: 0,
        pickup_charge: 0,
        tax_charge: 0,
        voucher: 0
      }
    },
    SET_IS_FETCHING_FIELDS(state) {
      state.isFetchingFields = !state.isFetchingFields
    },
    SET_CATEGORIES_FIELD(state, data) {
      state.categories = data
    },
    SET_PACKAGING_FIELD(state, data) {
      data.push({ value: 0, text: 'Others' })
      state.packaging = data
    },
    SET_MARKETS_FIELD(state, data) {
      state.markets = data
    },
    SET_REMARKS_FIELD(state) {
      state.remarks = [
        {
          text: 'Fragile',
          value: 'Fragile'
        },
        {
          text: 'Contains Alcohol (Special Handling)',
          value: 'Contains Alcohol (Special Handling)'
        },
        {
          text: 'Non Halal Items',
          value: 'Non Halal Items'
        },
        {
          text: 'Others',
          value: 'Others'
        }
      ]
    },
    SET_ALL_CLIENTS(state, data) {
      state.allClients = data
    },
    SET_GOLOG_ADDRESSES(state, data) {
      const gologAddresses = data
        .filter((client) => client.type === 3)
        .map((client) => client.addresses)
        .flat()
        .sort((a, b) => a.area.localeCompare(b.area))
      state.gologAddresses = Vue.helpers.formatAddresses(gologAddresses)
    },
    SET_USER_ADDRESSES(state, data) {
      state.userAddresses = Vue.helpers.formatAddresses(data)
    },
    SET_RECIPIENTS(state) {
      state.orderForm.recipient = null
      state.recipients = state.allClients.filter(
        (client) => client.type === state.orderForm.type_of_market.id
      )
    },
    SET_OUTLETS(state) {
      state.orderForm.outlet = null
      state.outlets = []
      if (state.orderForm.recipient.addresses) {
        const outlets = state.orderForm.recipient.addresses.sort((a, b) =>
          a.area.localeCompare(b.area)
        )
        state.outlets = Vue.helpers.formatAddresses(outlets)
      }
    },
    SET_PICKUP_ADDRESSES(state) {
      state.orderForm.sender_address = null
      state.pickUpAddresses =
        Number(state.orderForm.is_pickup) === 1
          ? state.gologAddresses
          : state.userAddresses
    },
    HANDLE_FETCHING_FIELDS_FAIL(state) {
      state.allClients = null
      state.categories = null
      state.packaging = null
      state.markets = null
      state.gologAddresses = null
      state.userAddresses = null
      state.remarks = null
    }
  },
  actions: {
    resetSenderAddress({ commit }) {
      commit('RESET_SENDER_ADDRESS')
    },
    setPickUpType({ commit }, { type }) {
      commit('SET_DELIVERY_TYPE', type)
    },
    updateOrderForm({ commit }, { data }) {
      commit('UPDATE_ORDER_FORM', data)
    },
    async fetchCharges({ commit, getters }) {
      try {
        const states = `${getters.filteredForm.sender_address.state},${getters.filteredForm.outlet_address.state}`
        let isOverSizeOrWeight = false
        if (
          getters.filteredForm.is_over_size ||
          getters.filteredForm.is_over_weight
        ) {
          isOverSizeOrWeight = true
        }
        let queryParameters = `states=${states}&quantity=${getters.filteredForm.qty}&deliveryType=${getters.filteredForm.is_pickup}&isOverSizeWeight=${isOverSizeOrWeight}`
        if (getters.filteredForm.voucher) {
          queryParameters = `states=${states}&quantity=${getters.filteredForm.qty}&deliveryType=${getters.filteredForm.is_pickup}&isOverSizeWeight=${isOverSizeOrWeight}&voucher=${getters.filteredForm.voucher}`
        }
        commit('SET_IS_FETCHING_PRICE', true)
        const response = await axios.get(`/prices/charges?${queryParameters}`)
        if (response.status === 422) {
          commit('SET_IS_FETCHING_PRICE', false)
          commit('RESET_CHARGES')
          return response
        }

        if (response.status === 200) {
          commit('SET_HAS_PRICE_SET', true)
          commit('SET_CHARGES', response.data)
        }

        if (response.status === 204) {
          commit('SET_HAS_PRICE_SET', false)
          Vue.swal({
            title: 'Contact Customer Service',
            text: 'Oops! Price is not set for these states, kindly proceed to contact with our Customer Service representative at this hotline 019-8196692. Thank you!',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#ea5455'
          })
        }
        commit('SET_IS_FETCHING_PRICE', false)
        return response
      } catch (error) {
        commit('RESET_CHARGES')
        throw new Error('Something went wrong.', error)
      }
    },
    async fetchFieldsValue({ commit }) {
      try {
        commit('SET_IS_FETCHING_FIELDS')
        const response = await axios.get('create-delivery-settings')
        if (response.status === 200) {
          commit('SET_ALL_CLIENTS', response.data.allClients)
          commit('SET_CATEGORIES_FIELD', response.data.categories)
          commit('SET_PACKAGING_FIELD', response.data.packaging)
          commit('SET_MARKETS_FIELD', response.data.markets)
          commit('SET_GOLOG_ADDRESSES', response.data.allClients)
          commit('SET_USER_ADDRESSES', response.data.senderAddress)
          commit('SET_REMARKS_FIELD')
        }
        commit('SET_IS_FETCHING_FIELDS')
      } catch (error) {
        commit('HANDLE_FETCHING_FIELDS_FAIL')
        throw new Error('Something went wrong.', error)
      }
    },
    setPickUpAddresses({ commit }) {
      commit('SET_PICKUP_ADDRESSES')
    },
    setRecipients({ commit }) {
      commit('RESET_RECIPIENT')
      commit('SET_RECIPIENTS')
    },
    setOutlets({ commit }) {
      commit('RESET_OUTLET_ADDRESS')
      commit('SET_OUTLETS')
    }
  }
}
