import axios from '@axios'

export default {
  namespaced: true,
  state: {
    transactions: [],
    links: null,
    meta: null,
    isLoading: false,
  },
  getters: {
    transactions: state => state.transactions,
    links: state => state.links,
    meta: state => state.meta,
    isLoading: state => state.isLoading,
  },
  mutations: {
    SET_TRANSACTIONS_DATA_SUCCESS(state, response) {
      state.transactions = response.data
    },
    SET_TRANSACTIONS_LINKS_SUCCESS(state, response) {
      state.links = response.links
    },
    SET_TRANSACTIONS_META_SUCCESS(state, response) {
      state.meta = response.meta
    },
    SET_TRANSACTIONS_FAILURE(state) {
      state.transactions = []
      state.links = null
      state.meta = null
      state.isLoading = false
    },
    SET_IS_LOADING(state, value) {
      state.isLoading = value
    },
  },
  actions: {
    async fetchTransactions({ commit }, { filters = '' }) {
      try {
        commit('SET_IS_LOADING', true)
        const { data } = await axios.get(
          `orders?${filters}`,
        )
        commit('SET_TRANSACTIONS_DATA_SUCCESS', data)
        commit('SET_TRANSACTIONS_LINKS_SUCCESS', data)
        commit('SET_TRANSACTIONS_META_SUCCESS', data)
        commit('SET_IS_LOADING', false)
      } catch (e) {
        commit('SET_TRANSACTIONS_FAILURE')
      }
    },
  },
}
