import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
// import * as Cookies from 'js-cookie'
// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import orders from './orders'
import deliveryTransactions from './delivery-transaction'
import notification from './notification'
import singleDelivery from './single-delivery'
import bulkDelivery from './bulk-delivery'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    // createPersistedState({
    //   paths: ['bulkDelivery'],
    //   storage: {
    //     getItem: key => Cookies.get(key),
    //     setItem: (key, value) => Cookies.set(key, value, { expires: 1, secure: true }),
    //     removeItem: key => Cookies.remove(key),
    //   },
    // }),
  ],
  modules: {
    app,
    appConfig,
    verticalMenu,
    deliveryTransactions,
    bulkDelivery,
    orders,
    notification,
    singleDelivery,
  },
  strict: process.env.DEV,
})
