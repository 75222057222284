import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2'
import Utility from './common/Utility'
import Validation from './common/Validation'
import i18n from '@/libs/i18n'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import UrlConfig from './libs/config'

// Utilities
const plugin = {
  install() {
    Vue.helpers = Utility
    Vue.prototype.$helpers = Utility
    Vue.validate = Validation
    Vue.prototype.$validate = Validation
  },
}

Vue.use(plugin)

Vue.use(require('vue-inputmask').default)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSweetalert2)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.prototype.$s3URL = 'https://minidc-live-spaces.sgp1.cdn.digitaloceanspaces.com/'
Vue.prototype.$processingURL = 'https://processing.golog.my/'
Vue.prototype.$barcodeUrl = new UrlConfig(window.location.host).getBarcodeUrl()

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
