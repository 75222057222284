<template>
  <div class="langPosition">
    <button
      href="#"
      class="flex items-center langDropdownButton"
      @click="toggleVisibility"
      @keydown.space.exact.prevent="toggleVisibility"
      @keydown.esc.exact="hideDropdown"
      @keydown.shift.tab="hideDropdown"
      @keydown.up.exact.prevent="startArrowKeys"
      @keydown.down.exact.prevent="startArrowKeys"
    >
      <img
        :src="`/flags/${$i18n.locale}.svg`"
        alt="flag"
        class="flagShadow"
      >
    </button>
    <transition name="dropdown-fade">
      <ul
        v-if="isVisible"
        ref="dropdown"
        class="langBox"
      >
        <li
          v-for="(lang, idx) in langs"
          :key="idx"
        >
          <a
            href="#"
            class="flex langHover"
            @click.prevent="setGlobalLocale(lang.shortName)"
            @keydown.up.exact.prevent
            @keydown.tab.exact="focusNext(false)"
            @keydown.down.exact.prevent="focusNext(true)"
            @keydown.esc.exact="hideDropdown"
          >
            <img
              :src="lang.flag"
              :alt="lang.longName"
              class="flag"
            >
            <span class="localLang">{{ lang.localName }}</span>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { localize } from 'vee-validate'

export default {
  name: 'LanguageSwitcher',
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
      langs: [
        {
          shortName: 'en',
          longName: 'English',
          localName: 'English',
          flag: '/flags/en.svg',
        },
        {
          shortName: 'ms',
          longName: 'Malay',
          localName: 'Malay',
          flag: '/flags/ms.svg',
        },
        {
          shortName: 'zh',
          longName: 'Chinese',
          localName: '中文',
          flag: '/flags/zh.svg',
        },
      ],
    }
  },
  created() {
    const locale = localStorage.getItem('lang') ?? 'en'
    this.$i18n.locale = locale
    localize(locale)
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible
    },
    hideDropdown() {
      this.isVisible = false
      this.focusedIndex = 0
    },
    startArrowKeys() {
      if (this.isVisible) {
        this.$refs.dropdown.children[0].children[0].focus()
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex -= 1
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusNext(isArrowKey) {
      this.focusedIndex += 1
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusItem() {
      this.$refs.dropdown.children[this.focusedIndex].children[0].focus()
    },
    setGlobalLocale(locale) {
      localize(locale)
      localStorage.setItem('lang', locale)
      // Uncomment the below lines if you need language prefixes to be shown in the URL
      // example: en.website.com (additional router configuration required)
      // this.$router.push({
      //   params: { lang: locale }
      // })
      this.hideDropdown()
      this.$router.go(0)
    },
  },
}
</script>

<style lang="scss" scoped>
// Position of language dot
.langPosition {
  z-index: 99999999;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 30px;
  left: 30px;
}
// Box that holds all language options
.langBox {
  user-select: none;
  padding: 5px 5px 5px -5px;
  width: max-content;
  box-shadow: 0px 3px 6px rgb(204, 210, 235);
}
// Language name text
.localLang {
  position: absolute;
  margin-top: 3px;
  font-size: 18px;
  padding-left: 15px;
  font-weight: 400;
  vertical-align: middle;
}
// Animations
.dropdown-fade-enter-active,
.dropdown-fade-leave-active {
  transition: all 0.1s ease-in-out;
}
.dropdown-fade-enter,
.dropdown-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}
.langDropdownButton {
  outline: none;
  cursor: pointer !important;
  background-color: transparent !important;
  border: none;
  padding-right: 0px;
}
.shortName {
  margin-top: 5px !important;
}
ul {
  text-align: left;
  min-width: 138px;
  border-radius: 8px;
  background: white;
  z-index: 500;
  position: absolute;
  list-style: none;
  padding: 10px 10px !important;
  margin: 0px !important;
  bottom: 60px;
  left: -10px;
  li {
    padding: 5px 0;
  }
}
.flag {
  width: 30px;
}
.flagShadow {
  border-radius: 50px;
  box-shadow: 0px 3px 3px rgb(204, 210, 235);
  width: 35px;
}
.langHover {
  margin: 10px;
  text-decoration: none;
  color: #222;
  transition: all 0.1s ease-in-out;
  &:hover {
    color: #007aff;
  }
}
</style>
