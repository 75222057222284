import axios from '@axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    orders: [],
    form: {
      sender_address: null,
      delivery_date: null,
      is_pickup: null,
      pickup_id: null,
      selectedVoucher: null
    },
    voucherDiscount: 0,
    pickupAddresses: [],
    allClients: [],
    markets: [],
    categories: [],
    packaging: [],
    remarks: [],
    userAddresses: [],
    gologAddresses: [],
    isLoading: false
  },
  getters: {
    form: (state) => state.form,
    orders: (state) => state.orders,
    remarks: (state) => state.remarks,
    categories: (state) => state.categories,
    packaging: (state) => state.packaging,
    markets: (state) => state.markets,
    userAddresses: (state) => state.gologAddresses,
    gologAddresses: (state) => state.gologAddresses,
    pickupAddresses: (state) => state.pickupAddresses,
    isLoading: (state) => state.isLoading,
    overallCharges: (state) =>
      state.orders.reduce(
        (previous, current) => ({
          subTotal: previous.subTotal + current.subTotal,
          totalDiscount: previous.totalDiscount + current.voucherDiscounts,
          pickupCharge: previous.pickupCharge + current.pickup_charge,
          taxCharge: previous.taxCharge + current.tax_charge,
          grandTotal:
            previous.subTotal +
            current.subTotal +
            (previous.pickupCharge + current.pickup_charge)
        }),
        {
          subTotal: 0,
          totalDiscount: 0,
          pickupCharge: 0,
          taxCharge: 0,
          grandTotal: 0
        }
      )
  },
  mutations: {
    SET_ORDERS_DATA_SUCCESS(state, response) {
      state.orders = response.data
    },
    SET_CATEGORIES_FIELD(state, data) {
      state.categories = data
    },
    SET_PACKAGING_FIELD(state, data) {
      data.push({ value: 0, text: 'Others' })
      state.packaging = data
    },
    SET_MARKETS_FIELD(state, data) {
      state.markets = data
    },
    SET_REMARKS_FIELD(state) {
      state.remarks = [
        {
          text: 'Fragile',
          value: 'Fragile'
        },
        {
          text: 'Contains Alcohol (Special Handling)',
          value: 'Contains Alcohol (Special Handling)'
        },
        {
          text: 'Non Halal Items',
          value: 'Non Halal Items'
        },
        {
          text: 'Others',
          value: 'Others'
        }
      ]
    },
    SET_ALL_CLIENTS(state, data) {
      state.allClients = data
    },
    SET_GOLOG_ADDRESSES(state, data) {
      const gologAddresses = data
        .filter((client) => client.type === 3)
        .map((client) => client.addresses)
        .flat()
        .sort((a, b) => a.area.localeCompare(b.area))
      state.gologAddresses = Vue.helpers.formatAddresses(gologAddresses)
    },
    SET_USER_ADDRESSES(state, data) {
      state.userAddresses = Vue.helpers.formatAddresses(data)
    },
    SET_RECIPIENTS(state, index) {
      state.orders[index].recipient = ''
      state.orders[index].fields.recipients = state.allClients.filter(
        (client) => client.type === state.orders[index].type_of_market.id
      )
    },
    SET_OUTLETS(state, index) {
      const outlets = state.orders[index].recipient.addresses.sort((a, b) =>
        a.area.localeCompare(b.area)
      )
      state.orders[index].outlet_address = ''
      state.orders[index].fields.outlets = Vue.helpers.formatAddresses(outlets)
      state.orders[index].client_id = state.orders[index].recipient.id
    },
    SET_PICKUP_ADDRESSES(state) {
      state.form.sender_address = null
      state.pickupAddresses =
        Number(state.form.is_pickup) === 1
          ? state.gologAddresses
          : state.userAddresses
    },
    ADD_ORDER(state, form) {
      state.orders.push(form)
    },
    UPDATE_FORM_DATA(state, data) {
      state.form = { ...state.form, ...data }
    },
    UPDATE_ORDER_FORM(state, { data, index }) {
      state.orders = state.orders.map((order, orderIndex) => {
        let temp = order
        if (orderIndex === index) {
          temp = { ...temp, ...data }
        }
        return temp
      })
    },
    SET_IS_LOADING(state, value) {
      state.isLoading = value
    },
    REMOVE_ORDER(state, index) {
      state.orders.splice(index, 1)
    },
    SET_CHARGES(state, { response, index }) {
      const charges = {
        amount: response.data.b2b,
        golog_cut: response.data.gologCut,
        smarttruck_cut: response.data.smarttruckCut,
        pickup_charge: response.data.pickUpCharge,
        tax_charge: response.data.tax_charge,
        voucherDiscounts: response.data.voucherDiscounts,
        price: response.data.totalCharges,
        subTotal: response.data.subTotal,
        unit_price: response.data.unitPrice
      }
      state.orders = state.orders.map((order, orderIndex) => {
        let temp = order
        if (orderIndex === index) {
          temp = { ...temp, ...charges }
        }
        return temp
      })
    },
    APPLY_VOUCHER_DISCOUNT(state, data) {
      state.orders = state.orders.map((order) => {
        const temp = order
        console.log(data)
        temp.voucherDiscounts =
          data.type === 'per_box' ? order.qty * data.discount : data.discount
        temp.subTotal = order.subTotal - temp.voucherDiscounts
        temp.smarttruck_cut = temp.subTotal - temp.golog_cut
        temp.price -= temp.voucherDiscounts
        console.log(temp)
        return temp
      })
    },
    REMOVE_VOUCHER_DISCOUNT(state) {
      state.orders = state.orders.map((order) => {
        const temp = order
        const previousDiscount =
          state.voucherType === 'per_box'
            ? order.qty * state.voucherDiscount
            : state.voucherDiscount
        temp.voucherDiscounts = 0
        temp.subTotal = order.subTotal + previousDiscount
        temp.smarttruck_cut = temp.subTotal + temp.golog_cut
        temp.price += previousDiscount
        return temp
      })
      state.voucherDiscount = 0
    },
    SET_VOUCHER_DISCOUNT(state, discount, type) {
      state.voucherDiscount = discount
      state.voucherType = type
    },
    RESET_OUTLET_ADDRESSES(state) {
      state.orders = state.orders.map((order) => {
        const temp = order
        temp.outlet_address = null
        temp.dropoff_id = null
        return temp
      })
    }
  },
  actions: {
    addOrder({ commit }, { form }) {
      commit('ADD_ORDER', form)
    },
    updateFormData({ commit }, { data }) {
      commit('UPDATE_FORM_DATA', data)
    },
    async fetchFieldsValue({ commit }) {
      try {
        commit('SET_IS_LOADING', true)
        const response = await axios.get('create-delivery-settings')
        if (response.status === 200) {
          commit('SET_ALL_CLIENTS', response.data.allClients)
          commit('SET_CATEGORIES_FIELD', response.data.categories)
          commit('SET_PACKAGING_FIELD', response.data.packaging)
          commit('SET_MARKETS_FIELD', response.data.markets)
          commit('SET_REMARKS_FIELD')
          commit('SET_GOLOG_ADDRESSES', response.data.allClients)
          commit('SET_USER_ADDRESSES', response.data.senderAddress)
        }
        commit('SET_IS_LOADING', false)
      } catch (error) {
        commit('SET_IS_LOADING', false)
        throw new Error('Something went wrong.', error)
      }
    },
    updateOrderForm({ commit }, { data, index }) {
      commit('UPDATE_ORDER_FORM', { data, index })
    },
    setRecipients({ commit }, { index }) {
      commit('SET_RECIPIENTS', index)
    },
    setOutlets({ commit }, { index }) {
      commit('SET_OUTLETS', index)
    },
    setPickupAddresses({ commit }) {
      commit('SET_PICKUP_ADDRESSES')
    },
    removeOrder({ commit }, { index }) {
      commit('REMOVE_ORDER', index)
    },
    async calculateCharges({ commit }, { order, index }) {
      try {
        if (
          order.sender_address &&
          order.outlet_address &&
          order.size &&
          order.weight &&
          order.qty > 0
        ) {
          const states = `${order.sender_address.state},${order.outlet_address.state}`
          let isOverSizeOrWeight = false
          if (order.is_over_size || order.is_over_weight) {
            isOverSizeOrWeight = true
          }
          const queryParameters = `states=${states}&quantity=${order.qty}&deliveryType=${order.is_pickup}&isOverSizeWeight=${isOverSizeOrWeight}`
          const response = await axios.get(`/prices/charges?${queryParameters}`)
          if (response.status === 200) {
            commit('SET_CHARGES', { response: response.data, index })
            return true
          }
        }
        return false
      } catch (error) {
        Vue.swal({
          title: 'Contact Customer Service',
          text: 'Oops! Price is not set for these states, kindly proceed to contact with our Customer Service representative at this hotline 019-8196692. Thank you!',
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#ea5455'
        })
        throw new Error('Something went wrong.', error)
      }
    },
    async applyVoucherDiscount({ commit, state }) {
      if (state.form.selectedVoucher) {
        const response = await axios.get(
          `/prices/voucher-discount/${state.form.selectedVoucher}`
        )
        if (response.status === 200) {
          console.log(response.data)
          commit(
            'SET_VOUCHER_DISCOUNT',
            response.data.discount,
            response.data.type
          )
          commit('APPLY_VOUCHER_DISCOUNT', response.data)
        }
      } else {
        commit('REMOVE_VOUCHER_DISCOUNT')
      }
    },
    resetOutletAddresses({ commit }) {
      commit('RESET_OUTLET_ADDRESSES')
    }
  }
}
