import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import BootstrapVue from 'bootstrap-vue'
import { extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import ms from 'vee-validate/dist/locale/ms_MY.json'
import zh from 'vee-validate/dist/locale/zh_CN.json'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

localize({
  en,
  ms,
  zh,
})

// with typescript
for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
  })
}

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.use(BootstrapVue)
Vue.use(require('vue-moment'))
